.field_length.kyc {
  width: 35%;
}

.business-main-section {
  width: 100%;
  /* background-color: #ffffff; */
}

.form-button .button-div,
.text-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-button .button-div {
  flex-wrap: wrap;
  gap: 1rem;
}
.text-div {
  gap: 0.6rem;
  margin-left: 1rem;
}

.form-button .text-div span {
  color: var(--text-7C);
}

.form-button .text-div img {
  width: 1.1rem;
  margin-right: 0.5rem;
}

.business-registration-section .check-box-group label {
  margin-right: 3rem;
}

.business-registration-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.business-details-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.business-address-section .check-box-group label span:nth-child(2) {
  color: #212121;
  font-size: 1rem;
  font-weight: normal;
}

.form-upload-section {
  gap: 2rem;
}

.kyc-safe-icon {
  width: 1rem !important;
}

.business-address-section .form-upload-section .upload-button {
  border-color: #cbcbcb !important;
  border-radius: 6px;
}

/* upload Documents start  */
.upload-documents-section
  .upload-documents-container
  .ant-upload.ant-upload-drag {
  background-color: #ffffff;
  border-radius: 5px;
}

.upload-documents-section .upload-icon {
  height: 6.5rem;
  margin: 2.8rem auto 1.5rem;
}

.upload-documents-section .upload-icon img {
  height: 100%;
}

.upload-documents-section .upload-text {
  font-size: 1.1rem !important;
  color: #212121;
  font-weight: 600;
}

.upload-documents-section .upload-text img {
  width: 0.9rem;
  margin-right: 0.5rem;
}

.upload-documents-section .upload-hint {
  font-size: 1.1rem;
  font-weight: 500;
  color: #7c7d7e;
  margin: 1.2rem auto;
}

.upload-documents-section .upload-button button {
  border-color: #cbcbcb !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  align-items: center;
  display: flex !important;
  min-width: 100px;
  justify-content: center;
  height: 42px !important;
}

.upload-documents-section .upload-button button span {
  color: var(--primary-color);
}

.business-registration-container .pp-form-item.businessFields {
  width: 45%;
  display: inline-grid;
  margin-right: 2rem;
}

.aadhaar_details .pp-form-item.businessFields,
.passport_details .pp-form-item.businessFields {
  width: 90%;
}
.aadhaar_details,
.passport_details {
  display: flex;
}
.business-registration-container .pp-form-item.businessFields.squareUpload {
  position: relative;
  width: 40%;
}

.aadhaar_details .upload-sec,
.passport_details .upload-sec {
  max-width: 100%;
}

.aadhar_ins {
  background: #fff9f5 0% 0% no-repeat padding-box;
  border: 1px solid #f0dfd6;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  margin-top: 3rem;
  max-width: 28rem;
}

.passport_details .aadhar_ins {
  max-height: 80vh;
}

.aadhar_ins img {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.aadhaar_details .aadhar_ins img {
  height: 12rem;
}

.passport_details .aadhar_ins img {
  height: 22rem;
}

.aadhar_ins ul li {
  color: #293241;
  margin-bottom: 1rem;
}

.business_image_section .upload-sec {
  border-radius: 8px;
  max-width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem 1.5rem;
}

.documents .business_image_section .upload-sec,
.passport_details .business_image_section .upload-sec,
.aadhaar_details .business_image_section .upload-sec {
  max-width: 100%;
}

.upload-sec .sub-title {
  color: #212121;
  font-size: 0.9rem;
  display: block;
}
.upload-sec .image_name {
  display: flex;
  align-items: flex-start;
}
.business_image_section .upload-sec .image_section {
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  height: 8rem !important;
  justify-content: center;
  max-width: 13rem !important;
}
.business_image_section .upload-sec .image_section img {
  max-width: 13rem !important;
}
.prevew_document_container .upload-sec .image_section img {
  max-width: 5rem !important;
}
.prevew_document_container .upload-sec .image_section {
  height: 8rem !important;
  justify-content: center;
  max-width: 13rem !important;
  min-width: 13rem !important;
}

.business_image_section .upload-sec .file_name {
  color: #212121;
  font-size: 1rem;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 15rem;
  overflow: hidden;
}

.business_image_section .upload-sec .file_verify {
  color: #2fcc46;
  background: #e5ffe9;
  border-radius: 4px;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.business_image_section .upload-sec .preview_section,
.business_image_section .upload-sec .delete_section {
  cursor: pointer;
  width: 1.5rem;
  height: 1rem;
}

.preview_section_modal .ant-modal-content .ant-modal-body {
  display: flex;
  justify-content: center;
  padding-top: 50px !important;
}

.business-registration-container
  .pp-form-item.businessFields.squareUpload
  .action_buttons {
  margin-top: 0.6rem;
}

.business-registration-container
  .pp-form-item.businessFields.squareUpload
  .hover_section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: none;
}

.business-registration-container
  .pp-form-item.businessFields.squareUpload:hover
  .hover_section {
  display: block;
}

.success_section {
  padding-top: 10rem;
}

.login-form .success_section {
  padding-top: 1rem;
}

.success_section .image_section {
  width: 13rem;
  height: 13rem;
}

.success_section .image_section img {
  width: 100%;
  height: 100%;
}

.success_section .application {
  color: #2c2c2c;
  font-size: 1.5rem;
}

.success_section .application_note span {
  color: #898989;
  font-size: 1.2rem;
  font-weight: 400;
}

.business-main-section .add-more-button {
  color: #3f81f4;
  font-size: 1.2rem;
  font-weight: 400;
}

.business-registration-section .header_section .remove_button {
  color: red;
}

.preview_section .preview_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #dbf1e6 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0.5rem 1rem;
}

.preview_section .info_image {
  width: 1rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.preview_section .info_text {
  color: #212121;
  font-size: 1rem;
  font-weight: 600;
}

.preview_section .header_section h4 {
  color: #212121;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem;
}

.preview_section .header_line {
  border: 1px solid #cecece;
}

.preview_section .preview_data {
  padding: 1.5rem 2rem;
  border: 1px solid #7070702e;
  border-radius: 8px;
  position: relative;
  min-height: 45vh;
}

.preview_data h2,
.preview_h2 {
  color: #122549;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.preview_section .preview_data .title {
  color: #05152c;
  opacity: 0.5;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.preview_section .preview_data .value,
.credit-score .key_value_div .value {
  color: #122549;
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

.preview_section .preview_edit {
  cursor: pointer;
}

.preview_edit img {
  width: 1rem !important;
  height: 1rem !important;
}

.align-center {
  align-items: center;
}

.preview_edit {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border: 1px solid var(--fillColor);
  border-radius: 5px;
  padding: 0.3rem 1rem;
}
.preview_data .key_value_div {
  display: inline-block;
  min-width: 16rem;
  max-width: 16rem;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.deviation-desc {
  background: #fff6f5 0% 0% no-repeat padding-box;
  border: 1px solid #dd5040;
  border-radius: 6px;
  padding: 1rem;
}

.preview_data .preview_section {
  width: 1.5rem;
}
.preview_data {
  flex: 1;
}
.prevew_document_section {
  margin-bottom: 1.5rem;
}
.view-deviations .prevew_document_section {
  padding: 0 1rem;
}
.prevew_document_section .image_section {
  width: 4rem;
  height: 3rem;
}
.w45 .preview_data .key_value_div {
  min-width: 100%;
  max-width: 100%;
}
/* transfer file css */
.showcase-box {
  border: 2px solid var(--text-d6);
  border-radius: 4px;
  width: 400px;
  padding: 0 10px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  display: inline-flex;
  cursor: pointer;
  min-height: 9rem;
  max-height: 9rem;
  position: relative;
}

.ant-steps-item-icon {
  /* background: #e4e4e4 !important; */
  border: none !important;
}

.ant-checkbox-inner::after {
  border-color: #fff !important;
}

/* .ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-active .ant-steps-item-icon {
  background: #ff9300 0% 0% no-repeat padding-box !important;
} */
.text-orginal {
  color: #ff9300;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #333333 !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #ff9300 !important;
}

.showcase-box a:hover {
  color: #333333 !important;
  /* color: #1890ff !important; */
}

.showcase-box a {
  color: #333333 !important;
}

.showcase-box:hover {
  background-color: #fafafa;
}

.showcase-box-dashed {
  border: 2px dashed var(--text-d6);
}

.transferText {
  width: 500px !important;
}

.rightContentTransfer {
  border-left: 1px solid var(--text-d9);
  padding: 0px 20px;
}

.arrowBackground {
  width: 30px;
  height: 30px;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #dddddd;
  text-align: center;
  padding-top: 3px;
  margin: 0px auto;
}

.quickLoadHead {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 9px;
}

.quickLoadHead p {
  color: #adadad;
  margin-bottom: 2px;
  font-weight: 400;
}

.quickLoadHead span {
  color: #1e1e1e;
  font-weight: 600;
}

.map-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.map-fields select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 2.5rem;
  min-width: 20rem;
}

.fields .header {
  background: #e4e9f2 0% 0% no-repeat padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 0.7rem 2rem;
  margin-bottom: 1rem;
}

.fields .header div {
  color: #333333;
  font-weight: 600;
}

.overview .fields .header .head-details,
.overview .fields .permissions ul {
  display: flex;
}
.overview .permissions ul li span.label {
  padding-left: 2rem;
}
.overview .permissions ul li,
.overview .permissions ul li span.label,
.overview .header h3 {
  min-width: 14rem;
}

.overview .head-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.overview .fields {
  width: 90%;
}
/* Bulk Transfers */
.upload_payment_parent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: fit-content;
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.step_div {
  margin: 2.8rem 0 3.75rem 15.6rem;
  width: 60%;
}

.step_div .ant-steps-item-wait .ant-steps-item-icon {
  background: #e4e4e4 !important;
}

.upload_payment_parent .ant-steps-item-finish .ant-steps-item-icon {
  background: #2cc426 !important;
}

.upload_payment_form {
  margin: 4rem 0 4.1rem 2rem;
}

.note_span {
  color: #58666e;
  opacity: 0.46;
  font-size: 1rem;
  font-weight: normal;
}

.right_div {
  margin: 7rem 0 0 8rem;
  display: flex;
  flex-direction: column;
}

.right_div .right_header {
  font-weight: normal;
  color: #262626;
  opacity: 1;
  font-size: 1.25rem;
}

.right_div .right_span {
  font-weight: normal;
  color: #262626;
  opacity: 0.46;
  font-size: 1rem;
  max-width: 70%;
}

.right_div .or {
  font-weight: normal;
  color: #262626;
  margin: 1rem 0;
  font-size: 1.1rem;
}

.right_div .right_button {
  width: 18.12rem;
  height: 3.1rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #3f81f4;
  cursor: pointer;
}

.right_div a.ant-btn {
  padding: 4px 15px !important;
}

.right_div .right_button span {
  color: #3f81f4;
  font-weight: medium;
}

.btn_div {
  display: flex;
  margin-left: 2rem;
  margin-bottom: 4.18rem;
}

.center_line {
  position: absolute;
  width: 0.007rem;
  height: 63%;
  background: #58666e;
  opacity: 0.46;
  top: 25%;
  left: 55%;
}

/* second upload div css */
.upload_payment_div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: fit-content;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.upload_payment_table {
  display: flex;
  margin: 2.75rem auto 1.5rem;
  width: 95%;
}

.instruts .ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}
.instruts .ant-modal-content {
  border-radius: 8px !important;
}

.instruts .ant-modal-footer {
  display: block !important;
}

.credit-modal .ant-modal-footer,
.sig-modal .ant-modal-footer {
  display: block;
}

.instruts .title {
  color: #293241;
  font-weight: 600;
  display: flex;
  gap: 0.5rem;
  font-size: 1.1rem;
}
.use-addr label {
  color: #293241;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.1rem;
}
.instruts .desc {
  color: #293241;
  width: 100% !important;
  max-width: 98% !important;
}
.instruts .desc ul {
  list-style: circle !important;
}
.instruts .desc ul li {
  color: #293241;
}

.instruts .sub-items {
  margin-left: 3rem;
}
.myspace {
  gap: 0px !important;
}

/* create payment css start  */
.amount_div div {
  padding-top: 1.2rem;
}

.link_div {
  font: 100;
}

.link_header {
  font-size: 1.15rem;
  margin-top: 1.5rem;
  color: #d8d8d8;
}

.link_div .link_display {
  float: left;
  color: rgba(0, 0, 0, 0.8);
  margin: 5px 0px;
  padding: 1px;
  width: 29.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
  padding-left: 3rem;
}

.share_link {
  margin-top: 5rem;
  font-size: 1.15rem;
}

.customer_div {
  padding: 0 0 0 4rem;
}

.customer_header {
  font-size: 1.1rem;
  margin-bottom: 0px;
}

.company_name {
  font-size: 1.1rem;
  padding-left: 1rem;
}

.link_line {
  border: 1px solid #d8d8d8;
  width: 66%;
  margin-left: 2rem;
  opacity: 0.3;
}

.upload_file .upload_buttom {
  width: 18.12rem;
  height: 3.1rem;
  margin-top: 1rem;
  border-radius: 5px;
  background-color: #f4f2f9;
  cursor: pointer;
  font-weight: 600;
  border-color: #d9d9d980;
}

.upload-sec .title,
.pp-form-item.businessFields .title,
.upload_file .upload_buttom span,
.supported {
  color: #293241;
}
.supported {
  font-size: 0.9rem;
}

.upload-sec .title {
  font-size: 1.2rem;
  font-weight: 600;
}

.pp-form-item.businessFields .title {
  font-weight: 600;
  margin-bottom: 0.6rem;
  display: inline-block;
  font-size: 1.1rem;
}

/* card css */
.card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin-top: 1rem;
  /* Adjust the width as needed */
}

.addrs .card {
  width: 550px;
  flex-direction: column;
  background: #fff;
}

.addrs .card.manual {
  background: #f2f6fa 0% 0% no-repeat padding-box;
  border: 1px dashed #d9d9d9;
  border-radius: 12px;
  min-height: 8rem;
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  flex: 0 0 100px;
  /* Adjust the width of the left side */
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left img {
  width: 60px;
  /* Adjust the size of the icon */
}

.right {
  padding: 20px;
}

.right h2 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.right p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

.right span {
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
}

.aadhar-card {
  gap: 1rem;
}
.live-score {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bar-live {
  position: relative;
}

.bar-live::after {
  content: "";
  position: absolute;
  left: 0;
  height: 45px;
  border-radius: 25px;
  width: var(--width);
  background-color: var(--color);
}

.bar-live::before {
  content: "";
  position: absolute;
  left: calc(var(--width) - 35px);
  background-color: var(--color);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: var(--br);
  width: 35px;
  height: 45px;
}
.preview_container .file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.preview_container .image_name .file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  margin-left: 5px;
}

.deviation_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 0.5rem;
}

.disclaimer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.disclaimer-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 40%;
  width: 100%;
}
.disclaimer-content .dim {
  color: #293241;
  opacity: 0.8;
  font-size: 1rem;
  /* font-weight: 500; */
  line-height: 1.6rem;
}
.disclaimer-head {
  display: flex;
  align-items: flex-start;
}

h2 {
  margin-top: 0;
}

.score-container {
  margin: 20px 0;
}

.score-item {
  margin-bottom: 10px;
}

.score-item span {
  display: block;
  margin-bottom: 5px;
  color: #293241;
  font-size: 1.2rem;
  font-weight: 500;
}

.progress-bar {
  width: 100%;
  background: #f7ebde;
  border-radius: 4px;
  overflow: hidden;
  height: 16px;
}

.progress-fuzzy {
  height: 100%;
  background: #689adb !important;
}

.score-percentage {
  margin-top: 5px;
}

.fuzzy-disclaimer {
  color: red;
  font-size: 1rem;
  margin: 20px 0;
}

.prevew_document_container {
  max-width: 27rem;
}
.prevew_document_container .image_section img {
  height: 4rem !important;
  max-width: 5rem !important;
}
.sticky-btns {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 0px;
  border-radius: 6px;
  margin: 14px;
}
.aadhar-addr-link {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.aadhar-addr-link span {
  color: #45a0fe;
}
.score-position {
  text-align: center;
  position: relative;
  top: -10rem;
}
.score-position .score-dim {
  color: #3d5a8080;
  font-weight: 600;
  font-size: 1.1rem;
}

.score-position .score {
  font-weight: 700;
  color: #3d5a80;
  font-size: 2.5rem;
  margin-bottom: 0;
}

.api-error-overlay .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: #fff;
  padding: 1rem;
  height: 20rem;
  inset: 0;
  margin: auto; /* This centers the element horizontally and vertically */
  max-height: 20rem; /* Set a max height to maintain the card size */
}

.api-error-overlay .card > * {
  text-align: center;
}
.api-error-overlay .card h2 {
  margin-top: 7rem;
}
.api-error-overlay .card img {
  position: absolute;
  top: -2rem;
}

.api-error-overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #00000034;
  border-radius: 8px;
  top: 0;
  left: 0;
  backdrop-filter: blur(9px);
  z-index: 10;
}
/* Media query to hide the menu icon for laptops and larger screens */
@media (max-width: 768px) {
  .json-fields .radio-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .disclaimer-content {
    max-width: 90%;
  }

  .json-fields .radio-div .title {
    margin-bottom: 5px !important;
  }
  .kyc-header.w70 {
    width: 100%;
  }

  .business-registration-container .pp-form-item.businessFields {
    width: 55% !important;
  }

  .business_upload {
    width: 85%;
  }

  .header-text {
    margin-left: 0rem;
  }

  .list-section .header-text {
    margin-left: 1rem;
  }

  .form-button {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .text-div {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }

  .business_image_section .business_image_container {
    width: 75%;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0rem !important;
  }

  .business_image_section .business_image_container .image_section {
    width: 2.5rem;
    height: 3.5rem;
  }
  .business_image_section .upload-sec .image_section img {
    height: 3rem !important;
    margin-right: 0.1rem;
  }

  .business-main-container {
    margin-left: 0.5rem;
  }
  .passport_details,
  .aadhaar_details {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .business-registration-container .pp-form-item.businessFields {
    width: 55%;
  }

  .business_upload {
    width: 90% !important;
  }

  .aadhaar_details .pp-form-item.businessFields,
  .passport_details .pp-form-item.businessFields {
    width: 90%;
  }
}

@media (min-width: 1000px) and (max-width: 1290px) {
  .aadhaar_details .aadhar_ins {
    max-width: 34rem;
  }

  .passport_details .aadhar_ins img {
    height: 22rem;
  }
  .passport_details .aadhar_ins {
    max-width: 38rem;
  }
}

@media (min-width: 1291px) and (max-width: 1445px) {
  .aadhaar_details .aadhar_ins {
    max-width: 30rem;
  }

  .passport_details .aadhar_ins img {
    height: 20rem;
  }

  .passport_details .aadhar_ins {
    max-width: 38rem;
  }
}
@media (max-width: 380px) {
  .business_image_section .right_section {
    position: relative;
    left: -10px;
    top: 5px;
  }
}

@media (max-width: 1000px) {
  .business-registration-container .pp-form-item.businessFields {
    width: 100% !important;
  }
  .aadhar-card {
    flex-direction: column;
  }

  .addrs .card {
    width: 100%;
  }

  .aadhar-card .card {
    width: 100%;
  }
  .preview_container.w60 {
    width: 100%;
  }

  .application,
  .application_note {
    text-align: center;
  }

  .capture button {
    font-size: 1.3rem;
    font-weight: 500;
    height: 42px;
  }

  .business_upload {
    width: 100% !important;
  }

  .business_image_section .upload-sec .file_name {
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .business_image_section .upload-sec .image_section {
    width: 3rem !important;
    height: 4rem !important;
  }
  .form-section {
    margin-left: 0 !important;
  }

  .form-section .form-button {
    align-items: flex-start !important;
  }
  .form-section .form-button.mt5 {
    margin-top: 2rem;
  }
  .upload-sec {
    max-width: 100% !important;
  }

  .navigation-section {
    display: none;
  }

  .form-button {
    margin-top: 1rem;
  }

  .upload-sec img.cloud {
    width: 2.5rem !important;
  }

  .pp-form-item.businessFields video {
    width: 100% !important;
  }
  .form-button .button-div .add-more-div,
  .form-button .button-div .pp-secondary-button {
    margin-right: 0.5rem !important;
  }

  .kyc-header-container .kyc-close {
    margin-bottom: 1.5rem !important;
  }

  .candidate-logout .circle {
    width: 1.5rem;
    height: 1.5rem;
  }

  .candidate-logout .circle .first-letter {
    font-size: 0.8rem;
  }

  .kyc-header-section .kyc-header .header-text {
    font-size: 1.8rem;
  }

  .kyc-header-section .kyc-header .sub-header-text {
    font-size: 0.9rem;
  }

  .form-button .text {
    font-size: 0.7rem;
  }

  .form-button .text-div img {
    margin-right: 0rem;
    width: 0.8rem;
  }

  .business-registration-container .pp-form-item.businessFields {
    margin-right: 0rem !important;
  }

  .kyc-header-container .kyc-close .ant-row {
    display: flex;
    justify-content: flex-end;
  }

  .menu .list-section .nested-list li {
    font-size: 0.8rem !important;
  }

  .business_image_section .business_image_container {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0rem !important;
  }

  .business_image_section .business_image_container .file_name {
    font-size: 0.8rem;
  }

  .business_image_section .business_image_container .image_section {
    width: 2rem;
    height: 3rem;
  }

  .business-registration-container .business_image_section {
    margin-top: 1rem;
  }

  .top-section .kyc-header-section {
    margin-bottom: 1rem;
  }

  .right_section .preview_section,
  .right_section .delete_section {
    width: 1.5rem !important;
    height: 0.8rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  html {
    font-size: 12px;
  }
  .navigation-section {
    display: block !important;
  }
  .list-section {
    padding-left: 5px !important;
    margin-bottom: 10px !important;
  }

  .menu .list-section .header-text {
    font-size: 0.9rem !important;
  }

  .header-circle {
    width: 1.2rem !important;
    height: 1.2rem !important;
    font-size: 0.7rem !important;
  }
  .completed-sec {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
  .completed-sec img {
    width: 10px !important;
    height: 6px !important;
  }

  .aadhaar_details .aadhar_ins img {
    height: 7rem !important;
  }
  .aadhaar_details,
  .passport_details {
    gap: 1.5rem;
  }

  .aadhar_ins {
    max-width: 15rem;
  }
  .aadhar_ins ul {
    padding: 1.5rem;
  }
  .aadhar_ins ul li {
    font-size: 0.95rem;
  }

  .business_image_section .upload-sec .image_section img {
    height: 5rem !important;
  }

  .business_image_section .upload-sec .image_section {
    width: 8rem !important;
    height: 5rem !important;
  }
  .upload_buttom span {
    font-size: 1.1rem;
  }

  .upload_buttom img {
    width: 13px;
  }
  .disclaimer-content {
    max-width: 70%;
  }

  .passport_details .aadhar_ins img {
    height: 13rem;
    padding: 1rem;
  }
}
