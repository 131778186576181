.business-registration-container .business_upload div:first-child {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  width: 100%;
}

.business-registration-container
  .buttonUpload
  .business_upload
  div:first-child {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
}

.business-registration-container
  .business_upload
  .upload-documents-section
  span:first-child {
  width: 100%;
}

.business-registration-container .business_upload .upload_file .upload_buttom {
  width: 12.12rem;
}
.business-main-container {
  margin-left: 2rem;
}

.business-registration-container
  .business_upload
  .upload-documents-section-label {
  font-size: 1rem;
  color: rgb(59, 59, 59);
  font-weight: 500;
  margin-bottom: 4px !important;
  display: inline-block;
}

.upload-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 1.7rem;
  max-width: 60%;
  justify-content: flex-start;
  margin-top: 2rem;
}

.documents .upload-sec {
  max-width: 100%;
  border: 1px solid #d9d9d980;
}
.border_btm {
  position: relative;
  height: 250px;
  width: 333px;
}
.border_btm video {
  border-radius: 5px;
}
.border_btm .top:after {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-bottom: 3px solid #2e1e39;
  border-bottom-right-radius: 5px;
  border-right: 3px solid #2e1e39;
}
.border_btm .top::before {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -10px;
  border-bottom-left-radius: 5px;
  left: -10px;
  border-bottom: 3px solid #2e1e39;
  border-left: 3px solid #2e1e39;
}
.border_btm:before {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  left: -10px;
  border-top: 3px solid #2e1e39;
  border-left: 3px solid #2e1e39;
  border-top-left-radius: 5px;
}
.border_btm:after {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: -10px;
  border-top: 3px solid #2e1e39;
  border-right: 3px solid #2e1e39;
  border-top-right-radius: 5px;
}

.json-fields .radio-block {
  padding: 1.5rem;
  background-color: #f4f2f97f;
  width: 100%;
  border-radius: 0px;
}
.json-fields .radio-div {
  display: flex;
}

.json-fields .radio-div .title {
  display: block;
  min-width: 20rem;
}

.sig-modal-content {
  padding: 1rem;
  background-color: #f4f2f9;
}

.settings_form .ant-select-selection-placeholder {
  line-height: 39px !important;
}

.view-deviations .titles {
  display: flex;
}

.view-deviations .titles strong {
  color: #363636;
  opacity: 0.6;
  min-width: 10rem;
  max-width: 10rem;
}
.view-deviations .titles p {
  color: #363636;
  opacity: 1;
  font-weight: 600;
}
.view-deviations .sect {
  background: #f7f9fc 0% 0% no-repeat padding-box;
  padding: 1rem;
  border-radius: 5px;
}
.view-deviations .ant-modal-body {
  padding: 0;
}
.strip {
  padding: 1rem;
  background: #e9edf1 0% 0% no-repeat padding-box;
  border-radius: 0;
}
.view-deviations .key_value_div .title {
  font-size: 1rem;
  min-width: 17rem;
  max-width: 17rem;
}
.view-deviations .key_value_div {
  padding-left: 1rem;
}
.view-deviations .key_value_div .flex-col {
  flex-direction: row !important;
}

.reference-details {
  display: grid;
  grid-template-columns: auto auto;
}

.reference_card {
  display: grid;
  grid-template-columns: auto;
}

.crime_check {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #f5f7fa;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 6px;
}

.preview_data .reference_card .key_value_div {
  min-width: 40rem;
  max-width: 40rem;
}

.permissions-wrap input {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  height: 35px;
  width: 5rem;
  text-align: center;
  color: #363636;
  margin-left: 4.5rem;
}

.profile-scoring .permissions-wrap .permissions ul {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.profile-scoring .ant-modal-body {
  padding: 0;
}

.profile-scoring .head-details h3 {
  min-width: 14rem !important;
  max-width: 14rem !important;
}
