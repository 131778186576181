.dark * {
  --primary-color: #212121 !important;
}
.dark .dash-header .title,
.dark .link,
.dark .applicant-table-sec p {
  color: #fff !important;
}

.dark .applicant-table .tab-active {
  background: #364254;
  color: #fff;
}
.dark .applicant-table-sec p {
  opacity: 0.7;
}
.dark .ant-layout {
  background: #121212;
}
/* .dark .table.tab.active {
  border-bottom: 3px solid #fafafa;
  color: #fafafa;
}

.dark .table.tab{
  color: #fafafa80;
} */
.dark .header_cards .card,
.dark .dashboard-content {
  background: #1c1e1f;
  box-shadow: none;
  border: none;
}
.dark .highcharts-background {
  fill: #1c1e1f;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark li,
.dark span,
.dark label,
.dark button {
  color: var(--text-white);
}
.dark .new-report-detail .sm,
.dark .report-head strong {
  color: #bfbfbf;
}
.dark .preview_section .header_section h4 {
  color: #ffffff;
}
.dark .comment-btn {
  background-color: var(--primary-color);
  border: none;
}
.dark .preview_section .preview_data .title {
  color: #ffffffae;
}
.dark .preview_section .preview_data .value {
  color: #bfbfbf;
}
.dark .application-details,
.dark .application-preview .sticky,
.dark .documents.ant-collapse,
.dark .documents.ant-collapse .ant-collapse-content {
  background-color: #1c1e1f !important;
}

.dark .paycraft-table .ant-table-thead > tr > th {
  background-color: #1c1e1f;
  border-bottom: #ffffff1a 1px solid;
}
.dark .paycraft-table .ant-table-tbody > tr > td {
  background-color: #1c1e1f !important;
  border-bottom: #ffffff1a 1px solid;
  color: #ffffffbb !important;
}
.dark .pp-input {
  background-color: #1c1e1f !important;
  border: 1px solid #ffffff6b !important;
}
.dark .dash-header .title label {
  color: #ffffff !important;
}
.dark .pp-input > input {
  background-color: transparent !important;
}
.dark .paycraft-table .ant-table-tbody > tr:hover > td {
  background-color: #1c1e1f !important;
  border-bottom: #ffffff1a 1px solid;
}

.dark .paycraft-table .ant-table-tbody tr:hover {
  background-color: transparent;
}

.dark .ant-empty-description {
  color: #bfbfbf;
}

/* .dark .paycraft-table .ant-table-tbody > tr > td {
  background: none !important;
}

.dark .paycraft-table .ant-table-tbody > tr:hover > td {
  background: none !important;
} */

.dark .header_cards .title {
  color: #fff;
}

.dark text {
  fill: #bfbfbf !important;
}

.dark text:hover {
  fill: #fff !important;
}

.dark .view-more {
  background-color: var(--primary-color);
}

.dark .dashboard-title .card-title {
  color: #fff;
}

.dark .contact-text {
  color: #fff;
}

.dark .table.tabs,
.dark .tabs-sec {
  background-color: #1c1e1f !important;
  border: none;
}

.dark .application-details .tabs-sec ul li {
  color: #bfbfbf;
}
.dark .verify .key_value_div .title {
  color: #ffffffb6 !important;
}
.dark .verify .key_value_div p,
.dark .address span {
  color: #fff;
}

.dark .application-details .tabs-sec ul li.active {
  color: #ff9900;
}

.dark .application-details .tabs-sec ul li.active {
  color: #ff9900;
}
.dark .application-details .tabs-sec ul li.active::before,
.dark .nav ul li.active::before {
  background-color: #ff9900;
}

.dark .nav ul li.active {
  color: #fff;
  background-color: #1c1e1f;
}

.dark .nav ul li {
  color: #bfbfbf;
}

.dark .sec-card {
  background-color: #1c1e1f;
}
.dark .no-records {
  color: #bfbfbf;
}

.dark .ant-input.comment-input {
  background-color: #f5f6fa1f;
}

.dark .score-factors,
.dark .mark-ok,
.dark .fields .header {
  background-color: #f5f6fa1f;
  border: none;
}

.dark .permissions ul li span,
.dark .list-table .tp {
  color: #bfbfbf !important;
}

.dark .list-table p {
  color: #bfbfbf;
}

.dark .score-position p,
.dark .score-position span {
  color: #bfbfbf !important;
}

.dark .preview_data h2 {
  color: #ffffff;
}

.dark .list-table .ant-list-items {
  background: #f5f6fa1f !important;
}

.dark .analytics-body {
  background: #f5f6fa1f !important;
}
