.header-div {
  width: 100%;
  /* height: 5rem; */
  background-color: #000;
  border-radius: 8px;
  padding: 0.65rem 1rem;
}

.header-div ul {
  margin-bottom: 0;
}

.header-div.width80 {
  width: 80%;
  margin: 0 auto;
}

.header-div .text-dim {
  color: #f5f7fa;
}

/* .header-div h3 {
  color: #f5f7fa !important;
} */

.header-logo {
  width: 10rem;
  margin-top: 9px;
  position: absolute;
  left: -15px;
  top: -1.3rem;
}

.header-drop {
  width: 90%;
}

.header-drop .ant-select-selector {
  border-radius: 1.5rem !important;
}

.header-drop .ant-select-selection-item {
  color: var(--text-primary) !important;
}

.logoContainer {
  margin-top: 0.3em;
}

.head-budget {
  font-size: 13px;
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.head-budget > * {
  color: #262626;
}

.head-budget h3 {
  font-size: 18px !important;
  color: #262626 !important;
}

.head-budget svg {
  fill: #262626;
}

.psd_content .ant-form-item-label label {
  color: var(--text-primary) !important;
}

.header-title {
  color: #262626;
  font-weight: 700;
  font-size: 2rem;
}

.first-letter {
  color: #122549;
  font-weight: 700;
  font-size: 1.2rem;
}

.header-div .circle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: 4px solid #e4e9f2;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.user-name {
  color: #ffffff;
  font-weight: 600;
}

.mrHalf {
  margin-right: 0.5rem;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: end;
}

.down {
  width: 1.3rem;
}

.rtl .user-drop .circle {
  margin-right: 0px;
  margin-left: 10px;
}
.kyc-header-section .user-name {
  color: #262626 !important;
}
.kyc-header-section .user-drop .circle {
  margin-right: 10px;
  background-color: rgba(63, 129, 244, 0.09);
  border-radius: 50%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
}

.dropdowntag > span,
.ant-dropdown-menu-item > span {
  color: #2e2e2e !important;
  font-size: 13px !important;
  padding: 3px;
  font-weight: 500;
}

.ant-dropdown-menu-item-active > span:hover {
  color: #3f81f4 !important;
}

.circle .ant-badge {
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parent_login {
  cursor: pointer;
  text-align: end;
  display: flex;
}

.parent_login .branch_name {
  white-space: nowrap;
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.parent_login .arrow_icon {
  margin-left: 0.5rem;
}

.branch_list .branch_list_select {
  padding: 1.5rem 0rem;
  cursor: pointer;
}

.branch_list .branch_list_div {
  cursor: pointer;
}

.branch_list .branch_list_div:hover {
  background: rgba(225, 225, 225, 0.3);
}

.branch_active_bg {
  background: rgba(225, 225, 225, 0.3);
}

.branch_list .branch_logo {
  margin-right: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 5px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branch_list .branch_logo img {
  width: 60%;
  height: 60%;
}

.branch_list .check_mark {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
}

.branch_list .check_mark img {
  width: 100%;
  height: 100%;
}

.branch_list .organisation_create,
.branch_list .organisation_id {
  color: #767676;
  font-size: 0.9rem;
  font-weight: 400;
}

.organisation_modal .ant-modal-body {
  padding: 24px 0px;
}

.organisation_modal .branch_list_div {
  padding: 0px 24px;
}

.ant-badge {
  position: relative;
}

.cart .ant-badge-count {
  position: absolute;
  top: -9px !important;
}

.ant-badge.cart {
  background: transparent;
  border: none;
}

.switch-portal h4 {
  font-size: 1rem;
  font-weight: 500;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #e4e9f2;
  transition: background-color 0.3s ease;
  width: 3rem;
  height: 3rem;
}

.switch-portal h4 span svg {
  width: 2rem;
  height: 1.4rem;
}

.tableHeader-select .ant-select-selector {
  width: 16rem !important;
  height: 45px !important;
  background-color: rgb(250, 250, 250) !important;
  border: 1px solid rgb(234, 234, 234) !important;
  border-radius: 5px !important;
}

.credits-sec {
  background: #ffffff20 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
}
.credit-block div {
  border-bottom: #fafafa 1px solid;
  padding: 0.5rem 0.2rem;
  width: 10rem;
}
.credit-block div:last-child {
  border-bottom: none;
}
.credit-block div label:first-child {
  color: #4b5660;
  font-size: 0.9rem;
  font-weight: 600;
}

.credit-block div label:last-child {
  color: #11264a;
  font-size: 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;
}
