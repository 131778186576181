@import url(./loan.css);

.report-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.report-btns .close {
  position: relative;
  bottom: 0;
  top: 0;
  margin-left: 1rem;
}

.report-btns button {
  height: 42px;
  border-radius: 10px;
  font-weight: 500;
}

.report-btns .more {
  margin-right: 0;
}

.strip {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1.1rem;
}

.strip.red {
  background-color: #d14046;
  color: #faeef1;
}

.strip.green {
  background-color: #208f1e;
  color: #faeef1;
}

.approve,
.reject,
.delete {
  color: white;
  min-width: 8rem;
  padding: 0 1rem;
}

.application-details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #689adb33;
  border-radius: 12px;
  /* padding: 1rem; */
}

.new-report-detail .status {
  border-color: inherit;
  border: 1px solid;
  height: 35px;
  display: inline-flex;
  align-items: center;
}

.new-report-detail .ant-table-cell .status {
  border: none;
}

.report-head {
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.new-report-detail .title-sec {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  font-size: 1.7rem;
  font-weight: bold;
}

.title-sec h3 {
  font-size: 1.7rem;
  margin-bottom: 0;
  color: #4b5660;
  font-size: 1.7rem;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 35rem;
}

.new-report-detail .sm {
  color: #1c2b36;
  opacity: 0.51;
  font-size: 0.9rem;
  font-weight: 500;
}

.new-report-detail .report-head p {
  margin-bottom: 0px;
  font-size: 0.95rem;
}

.new-report-detail p.duration {
  color: #2b2b2b;
  opacity: 0.9;
  margin-bottom: 5px;
  margin-top: 1rem;
}

.report-head strong {
  color: #1c2b36;
  font-weight: 600;
}

.tabs-sec {
  background: #f5f7fa 0% 0% no-repeat padding-box;
  border: 1px solid #edf0f5;
  padding: 0 2rem;
}

.application-details .tabs-sec {
  background: transparent;
  border: none;
}

.report-head~.report-total {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.report-total p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.report-total p span.lg {
  font-size: 2rem;
  color: #1c2b36;
}

.new-report-detail .report-total {
  position: relative;
  padding: 0 3rem;
  margin-right: 2rem;
}

.new-report-detail .report-total::before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 0;
  height: 6rem;
  width: 1.4px;
  background-color: #eaeaea;
}

.tabs-sec ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin-bottom: 0;
}

.tabs-sec ul li {
  color: #4b5660;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem 0;
}

.application-details .tabs-sec ul li {
  color: #4b5660;
}

.tabs-sec ul li.active {
  position: relative;
  color: #ff9900;
}

.tabs-sec ul li.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #ff9900;
}

.application-details .tabs-sec ul li.active::before {
  background-color: #ff9900;
}

.application-details .tabs-sec ul li.active {
  color: var(--primary-color);
}

.totalsSection,
.totalBar {
  color: #222222;
  font-weight: 600;
}

/* new Report Details  */
.details_p {
  display: flex;
  flex-direction: column;
}

.details_p .details_span.secondary {
  color: #1c2b36;
  opacity: 0.51;
  font-weight: 500;
}

.details_p .details_span.name {
  color: #4b5660;
  font-size: 1rem;
  font-weight: bold;
}

.details_div {
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(237, 240, 245);
  border-radius: 9px;
}

.timeline_div {
  margin-top: 3rem;
}

.details_div {
  padding: 1rem;
  min-height: 6rem;
}

.details_div p span {
  color: #4b5660;
  font-size: 1rem;
  font-weight: bold;
}

.details_div .details_span.secondary {
  color: #1c2b36;
  opacity: 0.51;
  font-weight: 500;
}

.my-custom-row {
  border-radius: 3rem;
}

.role-align .ant-form-item .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-css .ant-modal-body {
  padding: 3rem 5rem;
}

.modal-css .ant-modal-body div:nth-child(1) {
  border-top: 1px solid #ddd;
}

.prev-data-show {
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding: 0.5rem;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.view-details {
  background: #e6effe;
  padding: 4px;
  text-align: center;
  margin-top: 14px;
  border: 1px solid #e6effe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-details:hover {
  background: #c0d3f2;
  border-color: #c0d3f2;
}

.view-details p {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.2px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.application-preview {
  display: flex;
}

.application-preview .nav {
  width: 20%;
}

.application-preview .sticky {
  position: sticky;
  top: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #689adb33;
  border-radius: 12px;
  padding: 1.5rem;
  padding-top: 0.7rem;
  min-height: 90vh;
}

.application-preview .preview_container {
  width: 80%;
}

.application-preview .preview_card {
  min-height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50%;
}

.application-preview .comment-display {
  max-height: 28rem;
  overflow-y: auto;
}

.not-verified {
  background: rgba(253, 150, 150, 0.258) 0% 0% no-repeat padding-box;
  border: 1px solid rgb(255, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 0.9rem;
  color: red;
  font-weight: 500;
  padding: 0.3rem 1rem;
}

.verified {
  background: #36af4630 0% 0% no-repeat padding-box;
  border: 1px solid #36af4780;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #36af47 !important;
  font-weight: 500;
  padding: 0.3rem 1rem;
}

.application-preview .comment-display {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #f0f0f0;
  /* For other browsers */
}

/* For WebKit-based browsers (Chrome, Safari, Opera) */
.application-preview .comment-display::-webkit-scrollbar {
  width: 2px;
}

.application-preview .comment-display::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.application-preview .comment-display::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.message-interface {
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  padding: 10px;
  border-radius: 5px;
}

.message {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  width: 80%;
}

.message .sender-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sender {
  font-weight: bold;
  color: #333333;
}

.message-text {
  margin-top: 0.2rem !important;
  color: #1a2331;
  font-weight: 500;
  margin-left: 0.8rem;
  display: inline-block;
}

.message .timestamp {
  font-size: 12px;
  color: #1a2331;
  margin-top: 5px;
  font-weight: 500;
  margin-left: 0.3rem;
  margin-top: 0rem;
}

.massage-section {
  display: flex;
}

.massage-section.right-message {
  justify-content: end;
}

/* .message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #fff;
  margin-top: 8px;
}

.message-arrow.right-arrow {
  border-left: 8px solid #fff;
  border-right: none;
} */

.input-area {
  margin-top: 10px;
}

.input-area input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.info-text {
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
}

.input-area {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.input-area input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 10px;
}

.reply-btn {
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
}

.info-text {
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
}

.sec-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #98c1da33;
  border-radius: 8px;
  padding: 1.5rem;
}

.mark-ok {
  background-color: #faf7f2;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 6px;
}

.mark-ok label {
  cursor: pointer;
}

.mark-ok b {
  margin-left: 10px;
  opacity: 1;
}

.mark-ok span {
  color: #293241;
  opacity: 0.8;
}

.ant-input.comment-input {
  border: none;
  background: #f5f6fa 0% 0% no-repeat padding-box;
  border-radius: 6px;
  height: 45px;
}

.addComment {
  width: 50%;
}

.ant-btn.formButton {
  /* height: 45px; */
  padding: 0 1.5rem;
}

.sec-card .ant-steps-item-icon {
  background: #f4f7fa 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 12px #99aecc33 !important;
  border: 1px solid #e4edfa !important;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.sec-card .ant-steps-item-title {
  color: #1f1f1f;
  font-weight: 500;
}

.sec-card .ant-steps-item-description,
.no-records {
  font-weight: 500;
}

.sec-card .anticon svg {
  fill: #3d5a80;
  font-size: 16px;
}

.comment-btn {
  background: #f7f9fc 0% 0% no-repeat padding-box;
  border: 1px solid #689adb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1rem;
}

.cmt .ant-badge-count {
  left: -18px;
  width: 20px;
}

.message-interface .comment-input {
  background: #fff !important;
}

.message-interface button {
  height: 42px !important;
  font-weight: 500;
}

.sender {
  display: flex;
  align-items: center;
}

.sender label {
  text-transform: uppercase;
  color: #293241;
}

.sender .circle {
  background: #f2f6fa 0% 0% no-repeat padding-box;
  width: 2rem;
  height: 2rem;
  color: #293241;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
  margin-right: 10px;
  border-radius: 50%;
}

.no-records,
.no-records-desc {
  margin: 0;
}

.no-records {
  font-weight: 600;
  font-size: 1.1rem;
}

.no-records-desc {
  color: #1a2331;
}

.itr-expond-row-section {
  width: 78.5rem !important;
}

/* Approximation for screens below 15 inches */
@media screen and (max-width: 1440px) {

  .itr-expond-row-section {
    width: 74.5rem;
  }
}