.dashboard .footer.ant-row {
  gap: 1.5rem;
  flex-wrap: nowrap;
}

.table-tabs {
  padding: 0 1rem !important;
  /* margin-bottom: 1rem !important; */
}

.dashboard-tabs.table-tabs {
  padding: 0 !important;
  margin-bottom: 1.2rem;
}

.table-tabs .ant-tabs-tab-btn {
  padding: 1rem;
  cursor: pointer;
  color: #2f2f2f;
}

.table-tabs .tab-active {
  color: #3f81f4;
  position: relative;
}

.table-tabs .tab-active::before {
  height: 2.5px;
  background-color: #3f81f4;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
}

.dashboard .ant-tabs-tab-btn {
  font-weight: 600;
}

.dashboard-title {
  margin-bottom: 10px;
}

.dashboard-title .card-title {
  color: rgba(18, 37, 73, 0.48);
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
}

.rtl .dashboard-title .card-title {
  padding-right: 0px;
}

.dashboard-content {
  min-height: 190px;
}
.dashboard-content h2 {
  font-weight: 600;
}

.dashboard-title .help-icon {
  color: #d3d9e1;
  cursor: pointer;
  font-size: 18px;
}

.rtl .dashboard-title .help-icon {
  margin-right: 10px;
}

.dashboard-content {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  box-shadow: 0px 3px 12px #e3e4e5;
  padding: 20px 28px;
  width: 100%;
  position: relative;
}

.dashboard-table .dashboard-content {
  padding: 0;
  min-height: 25rem;
}

.card-label {
  color: rgba(5, 21, 44, 0.5);
  font-size: 1rem;
  min-width: 18rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-label .title {
  color: rgba(5, 21, 44, 0.5);
}
.card-label-overdue {
  color: #ed0000;
  font-size: 1rem;
  font-style: "regular";
}

.card-label-value {
  color: #122549;
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
}

.card-vertical-cont {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.card-footer-cont {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.circle-dashboard {
  background-color: rgba(63, 129, 244, 0.09);
  border-radius: 32px;
  height: 4rem;
  margin-right: 22px;
  min-width: 4rem;
}

.rtl .circle-dashboard {
  margin-left: 22px;
  margin-right: 0;
}

.circle-contact {
  align-items: center;
  background-color: #2f2f2f;
  border-radius: 32px;
  color: #ffffff;
  display: flex;
  font-size: 1.4rem;
  height: 50px !important;
  justify-content: center;
  width: 70px !important;
}

.contact-title {
  color: #00000099;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 26px;
}

.contact-text {
  color: #00000099;
  font-size: 1rem;
  line-height: 28px;
}

.download-app .dashboard-content {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.download-app .content {
  margin-left: 20px;
  margin-top: 60px;
}

.rtl .download-app .content {
  margin-right: 20px;
  margin-left: 0px;
}

.rtl .download-app .footer-contact .contact-text {
  margin-right: 20px;
  margin-left: 0px;
}

.download-app.footer-contact .contact-text {
  margin-left: 20px;
}

.download-app {
  flex-direction: column;
}

.rtl .download-app.footer-contact .contact-text {
  margin-right: 20px;
  margin-left: 0px;
}

.title-summary p {
  margin-bottom: 0;
}

.title-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.summary-wrap span.card-title {
  display: block;
  width: 100%;
}

.summary-wrap .ant-select-selector {
  background-color: transparent;
  border: none !important;
}

.highcharts-credits {
  display: none;
}

/* cars css */
.mt1Half {
  margin-top: 1.5rem;
}

.header_cards .card {
  display: flex;
  border-radius: 0.5rem;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: none;
  box-shadow: 0px 3px 10px 0px #98c1da29;
  background: white;
}
.list-table .tp {
  color: #333;
}
.list-table .application {
  padding: 0.6rem;
}
.list-table .application .details {
  margin-bottom: 0;
}

.list-table .ant-list-items {
  box-shadow: 0px 3px 10px 0px #98c1da29;
  background: white !important;
  padding: 0.6rem;
  border-radius: 8px;
}

.application:last-child {
  border-bottom: none;
}

.header_cards .title {
  color: #293241;
  opacity: 0.6;
  font-weight: 500;
}
.header_cards .left_line {
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #151424;
  border-radius: 41px 8px 41px 39px;
  /* border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 8px; */
}

.header_cards .text_div {
  padding: 1rem 0rem 1.5rem 1rem;
}

.header_cards .text_div.two_child {
  padding: 1rem 0rem 1rem 1rem;
}

.header_cards .card_circle {
  position: relative;

  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.header_cards .card_circle div {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ccc;
  margin-right: 1rem;
}

.header_cards .count {
  font-size: 2.3rem;
  font-weight: 600;
  color: #151424;
}

.graph .card {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}

.graph .card .subTitel {
  font-size: 1.1rem;
  font-weight: 400;
}

.header_cards .left_line.active {
  background: #60ba99;
}

.header_cards .left_line.in_active {
  background: #f3625b;
}

.header_cards .left_line.pending {
  background: #689adb;
}

.header_cards .left_line.total_reject {
  background: #eab969;
}

.highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #98c1da53 !important;
  stroke-dasharray: 4, 4;
  stroke-width: 1.5px;
}
.highcharts-axis-line {
  display: none;
}
.count span {
  color: #000;
}

.graphs {
  display: flex;
  gap: 1.5rem;
}
.applicant-table-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
.applicant-table-sec h2 {
  margin-bottom: 3px;
}
.applicant-table-sec p,
.list-table p {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #29324180;
}

.applicant-table-sec label,
.list-table label {
  font-weight: 500;
  color: #29324180;
}

.applicant-table-sec .ant-select-selector {
  border: none !important;
}
.applicant-table-sec .ant-select-selection-item {
  text-align: center;
  color: #293241;
  font-weight: 600;
  font-size: 1rem !important;
}
.applicant-table .tab-btn {
  color: #767676;
  font-weight: 600;
  padding: 0.7rem 2rem;
  cursor: pointer;
}

.applicant-table thead > tr > th {
  /* background: none !important; */
  color: #293241 !important;
  opacity: 0.5 !important;
  /* text-align: center; */
  font-size: 1.1rem;
}
.dark .ant-select-selector {
  background-color: transparent !important;
}

.dark .ant-select-selection-item {
  color: #bfbfbf !important;
}
.dark .applicant-table thead > tr > th {
  color: #bfbfbf !important;
}
.applicant-table thead > tr > th:first-child,
.applicant-table .ant-table-tbody tr:hover > td:first-child,
.applicant-table tbody > tr > td:first-child {
  padding-left: 2rem;
}
.applicant-table tbody > tr > td {
  color: #293241 !important;
  /* text-align: center !important; */
}

.applicant-table .tab-active {
  background: #e9edf1 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0.7rem 2rem;
  font-weight: 600;
  color: var(--primary-color);
}
.applicant-table .table-tabs {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.applicant-table .table-tabs .tab-active::before {
  display: none;
}
.view-more {
  background: #e9edf1 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 90%;
  padding: 0.7rem;
  font-weight: 500;
  color: var(--primary-color);
  position: absolute;
  bottom: 5%;
  left: 5%;
}
.dash-header {
  margin: 1rem 0;
  margin-left: 5px;
}
.dash-header .title {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #293241;
}

.bb-line {
  border-bottom: 1px solid #ececec9b;
  padding-bottom: 1rem;
}
.dashboard-content .bb-line:last-child {
  border-bottom: none !important;
}

.dash-header .title span {
  display: block;
  font: normal normal 600 1rem Libre Franklin;
  letter-spacing: 0px;
  color: #293241;
  margin-top: 0.5rem;
}

.dash-header .ant-picker {
  border: none;
  border-radius: 8px;
  width: 12rem;
  height: 45px;
}

.dash-header .ant-picker .ant-picker-input input {
  text-align: center;
  color: #293241;
  font-weight: 600;
}

.mobile {
  display: none !important;
}

.tablet {
  display: none;
}

@media (max-width: 700px) {
  .graphs {
    flex-direction: column;
  }

  .mobile {
    display: block !important;
  }

  .right_content p.title {
    max-width: 100%;
  }

  .graphs .w70,
  .graphs .w30 {
    width: 100% !important;
  }

  .desktop.d-flex {
    display: none !important;
  }
  .desktop {
    display: none !important;
  }
  .dashboard-content h2 {
    font-size: 1.3rem;
    padding: 0.7rem 0;
  }
  .dashboard-content {
    padding: 10px;
  }

  .header-right .flex-center {
    justify-content: flex-end;
  }

  .new-report-detail .details {
    flex-wrap: wrap;
  }
  .content.rcu .w40 {
    width: 100%;
  }
}
@media (max-width: 1195px) {
  .application {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 0 1.2rem;
  }

  .application .details {
    max-width: 60%;
    display: flex;
    flex-direction: column;
  }

  .application .details p {
    margin: 0;
  }

  .application-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.application-contact p {
  margin: 0;
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .card-label {
    min-width: 14.5rem;
    max-width: 14.5rem;
  }

  .card-label-value {
    font-size: 1.1rem;
  }
}

/* Tab */

@media (min-width: 768px) and (max-width: 1195px) {
  .content .w40 {
    width: 90%;
  }

  .details {
    flex-wrap: wrap;
  }

  .leads,
  .tp-apps {
    display: none !important;
  }

  .total-applications,
  .dashboard-table {
    width: 100% !important;
  }

  .tablet {
    display: flex !important;
    gap: 1rem;
  }

  .login-container.tablet {
    flex-direction: column;
  }
  .login-right-section {
    margin: auto;
  }
  .tablet .login-left {
    width: 100% !important;
    transform: scale(0.8);
  }
}
