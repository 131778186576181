.__floater__arrow svg polygon {
  fill: #1c1c1c;
}

.header-tour ~ .__floater__arrow svg polygon {
  fill: #d0dfef;
}
.overview-tour {
  max-width: 30rem;
  background-color: #1c1c1c;
  padding: 1rem;
  border-radius: 6px;
}

.overview-tour h4 {
  font-size: 1.1rem;
  color: #fff;
}

.overview-tour p {
  font-size: 1.1rem;
  color: #ffffff9e;
}

.overview-tour .btns,
.header-tour .btns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.btns button {
  cursor: pointer;
}
.btns .skip {
  color: #ffffff9e;
  text-decoration: underline;
}

.btns .next {
  padding: 10px 20px;
  background: #ffffff27 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
}
.btns .next span {
  margin-left: 5px;
  font-size: 0.85rem;
}

.header-tour {
  background-color: #fff;
  border-radius: 10px;
  max-width: 30rem;
}

.header-tour img {
  width: 20rem;
  box-shadow: 0px 3px 6px #151a2026;
}

.header-tour .img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #d0dfef;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-tour .content-wrap {
  padding: 1rem;
}

.header-tour .btns button,
.header-tour .btns button span {
  color: #284d75;
}

.header-tour .next {
  background: #284d7515 0% 0% no-repeat padding-box;
}
